import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PartnerFeatures.module.css';

import Button from '../../../../elements/Button';
import Container from '../../../../blocks/Container';
import FullWidthRow from '../../../../blocks/FullWidthRow';
import Pretitle from '../../../../elements/Pretitle';
import Text from '../../../../elements/Text';
import Title from '../../../../elements/Title';

import Testimonials from './Testimonials'

const PartnerFeatures = ({ ctaUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/stealth-selling.md/" } }) {
        edges {
          node {
            frontmatter {
              statements {
                modules {
                  pretitle
                  title
                  description
                  type
                  image
                }
                bonuses {
                  pretitle
                  title
                  description
                  type
                  image
                }
              }
            }
          }
        }
      }
    }
  `);

  const { modules } = data.allMarkdownRemark.edges[0].node.frontmatter.statements[0];
  const { bonuses } = data.allMarkdownRemark.edges[0].node.frontmatter.statements[1];

  return (
    <div className={styles.partnerFeatures}>
      <Container>
        <Container type="mobile">
        <div className="row">
            {
              modules.map((statement, index) => (
                <div key={index} className={`${styles.partnerFeatures__feature} col-xl-6 col-lg-6 col-12 order-xl-0 order-lg-0 order-0`}>
                  <div className={styles.partnerFeatures__featureBlock}>
                    <Pretitle>
                      <div className={`${styles.partnerFeatures__marketingAutomationsSlideIcon} ${styles[`module_${index + 1}`]}`} />
                      {statement.pretitle}
                    </Pretitle>
                    <Title type="small" className={`${styles.partnerFeatures__title}`}>{statement.title}</Title>
                    <Text className={`${styles.partnerFeatures__desc}`}>
                      {statement.description}
                    </Text>
                  </div>
                </div>
              ))
            }
          </div>
          </Container>
        </Container>
        <Container type="big">
        <Container type="mobile">
          <Testimonials />
        </Container>
      </Container>
    </div>
  )
};

PartnerFeatures.propTypes = {
  ctaUrl: PropTypes.string.isRequired
};

export default PartnerFeatures;