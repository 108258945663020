import React, { Fragment } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './Testimonials.module.css';

import Title from '../../../../../elements/Title';
import Text from '../../../../../elements/Text';
import Image from '../../../../../elements/Image';
import Container from '../../../../../blocks/Container';
import Slider from 'react-slick';

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/stealth-selling.md/" } }) {
        edges {
          node {
            frontmatter {
              description
              testimonials {
                title
                subtitle
                previewImage
                href
              }
            }
          }
        }
      }
    }
  `);

  const { description, testimonials } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const sliderSettings = {
    className: 'brand-slider',
    centerMode: true,
    infinite: true,
    swipeToSlide: true,
    centerPadding: '0',
    slidesToShow: typeof window !== 'undefined' && window.innerWidth <= 768 ? 1 : 2,
    speed: 500,
    dots: false,
    initialSlide: 1,
    autoplay: false,
    centerPadding: '30px',
  };

  const renderCaseContainer = testimonial => (
    <a href={testimonial.href} className={styles.testimonials__item} data-lity>
      <div className={styles.testimonials__videoPreview}>
        <Image name={testimonial.previewImage} />
        <Image name="play" className={styles.testimonials__playImage} />
        <div className={styles.testimonials__playImageMobile} />
      </div>
      <div className={styles.testimonials__description}>
        <div className={styles.testimonials__title}>{testimonial.title}</div>
        {testimonial.title2 && <div className={styles.testimonials__title}>{testimonial.title2}</div>}
        <div className={styles.testimonials__subtitle}>{testimonial.subtitle}</div>
      </div>
    </a>
  );

  return (
    <div className={styles.testimonials}>
      <Title className={styles.testimonials__topTitle} type="mediumBig">Testimonials</Title>
      <Container type="mobile" className="d-flex justify-content-center">
        <Text className={styles.testimonials__subtitleContainer}>{description}</Text>
      </Container>
      <div className="row d-xl-flex d-lg-flex d-none">
        {
          testimonials.map((testimonial, index) => (
            <div key={index} className="col-xl-4 col-lg-4 col-12 d-flex justify-content-center">
              {renderCaseContainer(testimonial)}
            </div>
          ))
        }
      </div>
      <Container>
        <div className="d-xl-none d-lg-none d-block">
          <Slider {...sliderSettings}>
            {
            testimonials.map((testimonial, index) => (
              <div key={index} className="col-xl-4 col-lg-4 col-12 d-flex justify-content-center px-0">
                {renderCaseContainer(testimonial)}
              </div>
            ))
          }
          </Slider>
        </div>
      </Container>
    </div>
  )
};

export default Testimonials;