import React from 'react';
import PropTypes from 'prop-types';

import styles from './Pretitle.module.css';

const Pretitle = ({ children, className }) => (
  <div className={`${styles.pretitle} ${className}`}>
    {children}
  </div>
);

Pretitle.defaultProps = {
  className: ''
};

Pretitle.propsTypes = {
  className: PropTypes.string,
  children: PropTypes.node.required
};

export default Pretitle;