import { createSelector } from '@reduxjs/toolkit';

export const selectSendingDetails = state => state.signup.sendingDetails;
export const selectPlans = state => state.signup.customContent.details;
export const selectPlanDetails = state => state.signup.customContent;
export const selectTestimonial = state => state.signup.customContent.testimonial;
export const selectVideo = state => state.signup.customContent.video;
export const selectUserDetails = state => state.signup.userDetails;
export const selectRedirectUrl = state => state.signup.redirectUrl;
export const selectTrialInDays = state => state.signup.trialInDays;
export const selectSpecial = state => state.signup.special;
export const selectLoading = state => state.signup.loading;
export const selectRegularPaymentType = state => state.signup.paymentType;
export const selectSpecialPaymentType = state => state.signup.customContent.paymentType;

export const selectShowPaymentDetailsForm = createSelector(
  selectUserDetails,
  userDetails => Object.keys(userDetails).length !== 0
);

export const selectPaymentType = createSelector(
  [selectSpecial, selectRegularPaymentType, selectSpecialPaymentType],
  (isSpecial, regularPaymentType, specialPaymentType) => isSpecial ? specialPaymentType : regularPaymentType
);
