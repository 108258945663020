import React, { useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./PartnerHeader.module.css";

import Button from "../../../../elements/Button";
import Title from "../../../../elements/Title";
import Text from "../../../../elements/Text";
import Container from "../../../../blocks/Container";
import FullWidthRow from "../../../../blocks/FullWidthRow";
import Image from "../../../../elements/Image";
import WistiaEmbed from "../../../../elements/WistiaEmbed";
import MainImage from "../../../../../images/svg/congrates_party.svg";
import { useDispatch, useSelector } from "react-redux";
import { payUpsell } from "../../../../../modules/signup/store";
import { selectRedirectUrl } from "../../../../../modules/signup/store/selectors";

const PartnerHeader = ({ partner }) => {

  const redirectUrl = useSelector(selectRedirectUrl);

  const dispatch = useDispatch()

  const handleClickPayUpsell = () => {
    dispatch(payUpsell())
  }

  useEffect(() => {
    if (redirectUrl) window.location.assign(`${process.env.APP_URL}${redirectUrl}`);
  }, [redirectUrl]);

  return (
    <div className={styles.partnerHeader}>
      <Container>
        <Container type="mobile">
          <div className="row mb-md-4 mb-5 justify-content-center">
            <div className="col-11 on-top">
              {(partner.thankYouPage || true) && (
                <div className={`${styles.partnerHeader__thank}`}>
                  <MainImage className={styles.partnerHeader__thank_party} />
                  <Text className={styles.partnerHeader__thank_text}>
                    Thank you for joining Deadline Funnel! Before you proceed to
                    your account, take a look at this limited time offer…
                </Text>
                </div>
              )}
            </div>
          </div>
          <div className="row mb-4 justify-content-center position-relative">
            <div className="col-11 on-top">
              <Title type="medium">
                Stealth Selling: How to Use Teaching and Selling to Grow the
                Impact of Your Email Marketing
            </Title>
              <Text className={`${styles.partnerHeader__desc}`}>
                Learn how to blend teaching and selling so that every single email
                in your campaign moves your readers closer to becoming customers.
                8+ hours of video training, slides, and more!
            </Text>
            </div>
            <div className={styles.partnerHeader__confetti_1} />
            <div className={styles.partnerHeader__confetti_2} />
            <div className={styles.partnerHeader__confetti_3} />
            <div className={styles.partnerHeader__confetti_4} />
            <div className={styles.partnerHeader__confetti_5} />
            <div className={styles.partnerHeader__confetti_6} />
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12 order-xl-1 order-lg-0 order-0 d-flex flex-column align-items-md-center h-100">
              <div className={`${styles.partnerHeader__video}`}>
                <WistiaEmbed type="inline" hashedId={partner.wistiaHash} />
              </div>
              <Image name="guy" className={`${styles.partnerHeader__guy}`} />
              <Image name="girl" className={`${styles.partnerHeader__girl}`} />
            </div>
          </div>
          <div className={`${styles.partnerHeader__topButtonMargin} row`}>
            <div className="col-12 on-top p-0">
              <div className="d-flex justify-content-center mt-1 mt-lg-5">
                <button onClick={handleClickPayUpsell} className={styles.partnerHeader__payButton}>
                  Yes, I'm in for a one time $99 payment
                </button>
              </div>
            </div>
          </div>
          <FullWidthRow className={styles.partnerHeader__skipActionContainer}>
            <div className={styles.partnerHeader__skipLink}>
              <a href="http://test" rel="nofollow" target="blank">
                No Thanks, I’m already an expert on everything.
            </a>
            </div>
            <span className={styles.partnerHeader__skipDescription}>
              *You’ll be charged immediately and we’ll send you a receipt.
          </span>
          </FullWidthRow>
          <FullWidthRow>
            <Title type="medium">
              No more awkward shifts from "pure
            <br /> teaching" to "pure selling" in your emails
          </Title>
            <Text className={`${styles.partnerHeader__desc_bottom}`}>
              You’ll weave your selling into your teaching so even your first
              email to a new subscriber is moving them closer to a sale. Every
              email you send will have a persuasion purpose.
          </Text>
          </FullWidthRow>
        </Container>
      </Container>
      <div className={styles.partnerHeader__clouds} />
    </div>

  )
};

PartnerHeader.propTypes = {
  partner: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    wistiaHash: PropTypes.string.isRequired,
    ctaUrl: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    audienceDescription: PropTypes.string,
  }).isRequired,
};

export default PartnerHeader;
